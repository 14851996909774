<template>
  <div class="container">
    <slot />
  </div>
</template>

<style scoped lang="scss">
  .container {
    max-width: 1440px;
    margin: 12px auto;
  }
</style>
